import React from 'react'

import {
  WppTypography,
  WppTag,
  WppCard,
  WppDivider,
} from '@wppopen/components-library-react'

import styles from './LandingPage.module.scss'

export interface WelcomeMessageProps {
  userData: {
    userEmail: string
    groups: { name: string }[]
    organizationId: number
  }
  agency: string
}

export default function WelcomeMessage(props:  Readonly<WelcomeMessageProps>) {
  const { userData, agency } = props;
  return (
    <div className={styles.welcomewrapper}>
      <div className={styles?.welcomeSection}>
        <WppTypography type="m-body">
          Welcome, <WppTypography type="m-strong">{userData?.userEmail}</WppTypography>
        </WppTypography>
        <WppTypography type="m-body">
          Organisation {' : '}
          <WppTypography type="m-strong">{agency}</WppTypography>
          {userData?.organizationId === -1 ? (
            <WppTag
              label="No organisation mapped. Please create a support ticket."
              variant="warning"
              maxLabelLength={180}
              className={styles.tag}
            />
          ) : null}
        </WppTypography>{' '}
        <WppTypography type="m-body">
          Permissions {' : '}
          <WppTypography className={styles?.group}>
            {userData?.groups.length > 0 && (
              <>
                {userData?.groups?.map((group, index) => {
                  return (
                    <WppTypography className={styles?.group} key={group?.name} type="m-strong" tag="span">
                      {group?.name}
                      {index < userData.groups.length - 1 ? ',' : ''}
                    </WppTypography>
                  )
                })}
              </>
            )}
          </WppTypography>
          {userData?.groups.length === 0 ? (
            <WppTag
              label="No permissions mapped. Please create a support ticket."
              variant="warning"
              maxLabelLength={180}
            />
          ) : null}
        </WppTypography>
      </div>
      <div className={styles?.relasesDetails}>
        <WppCard className={styles.relasesDetailsCard}>
          <WppTypography type="m-strong">v2.1.0 - what’s new? </WppTypography>
          <div className={styles.listItem}>
            <ul>
              <li>
                <WppTag
                  label="New industries and service types."
                  maxLabelLength={60}
                />
              </li>
              <WppDivider />
              <li>
                <WppTag
                  label="WPP VSA template."
                  maxLabelLength={65}
                />
              </li>
              <WppDivider />
              <li>
                <WppTag
                  label="Template-specific email and branding."
                  maxLabelLength={65}
                />
              </li>
              <WppDivider />
              <li>
                <WppTag
                  label="Template-specific risk calculation."
                  maxLabelLength={65}
                />
              </li>
              <WppDivider />
              <li>
                <WppTag
                  label="Vendor description is now editable."
                  maxLabelLength={65}
                />
              </li>
              <WppDivider />
            </ul>
            <WppTypography type="m-strong">Updates available from previous release:</WppTypography>
            <ul>
            <li>
                <WppTag
                  label="Offline Assessment Import function for GroupM template."
                  maxLabelLength={60}
                />
              </li>
              <WppDivider />
              <li>
                <WppTag
                  label="Reviewers can now upload documents on behalf of vendor."
                  maxLabelLength={65}
                />
              </li>
              <WppDivider />
              <li>
                <WppTag
                  label="Vendors are no longer notified when an assessment is deleted."
                  maxLabelLength={65}
                />
              </li>
              <WppDivider />
              <li>
                <WppTag
                  label="Motivator agency and motivatorworld.com domain added."
                  maxLabelLength={65}
                />
              </li>
              <WppDivider />
              <li>
                <WppTag
                  label="Bug fix: reviewer can now add comments to assessment during Response Pending stage."
                  maxLabelLength={65}
                />
              </li>
              <WppDivider />
            <li>
                <WppTag
                  label="The TPRM and Privacy modules have been merged into a single module, called Vendor Risk Management."
                  maxLabelLength={60}
                />
              </li>
              <WppDivider />
              <li>
                <WppTag
                  label="Each vendor can now have multiple assessments."
                  maxLabelLength={65}
                />
              </li>
              <WppDivider />
              <li>
                <WppTag
                  label="Vendors can now have optional Country and Industry values."
                  maxLabelLength={65}
                />
              </li>
              <WppDivider />
              <li>
                <WppTag
                  label="Vendors now have Subject Types and Data Subject Location values, which are automatically populated via assessment responses."
                  maxLabelLength={65}
                />
              </li>
              <WppDivider />
              <li>
                <WppTag
                  label="Improved sorting and filtering in the Vendors and Assessments tables."
                  maxLabelLength={65}
                />
              </li>
              <WppDivider />
              <li>
                <WppTag
                  label="Assessments can now be deleted without also deleting their associated vendor."
                  maxLabelLength={65}
                />
              </li>
              <WppDivider />
              <li>
                <WppTag
                  label="Filtering by Network is now done within the tables."
                  maxLabelLength={65}
                />
              </li>
              <WppDivider />
              <li>
                <WppTag
                  label="Risk score on Assessment card now updates in real time."
                  maxLabelLength={65}
                />
              </li>
              <WppDivider />
              <li>
                <WppTag
                  label="Export PDF button on Vendor card now generates a report of the vendor’s details, including a summary of the most recently approved assessment (per template)."
                  maxLabelLength={65}
                />
              </li>
              <WppDivider />
              <li>
                <WppTag
                  label="When deleting a vendor or assessment, the record is now “soft deleted” and can be recovered if necessary."
                  maxLabelLength={65}
                />
              </li>
              <WppDivider />
            <li>
                <WppTag
                  label="OneTrust data available for Choreograph survey."
                  maxLabelLength={65}
                />
            </li>
                <WppDivider />
            <li>
                <WppTag
                  label="Assessments that have already been approved or rejected can now be relaunched by DPMs."
                  maxLabelLength={65}
                />
            </li>
                <WppDivider />
            <li>
                <WppTag
                  label="Added Total Risk Score column to Assessments table."
                  maxLabelLength={65}
                />
              </li>
              <WppDivider />
            <li>
                <WppTag
                  label="Added new Reviewed By, Reviewer Action, and Reviewed At fields to the assessment table."
                  maxLabelLength={65}
                />
              </li>
              <WppDivider />
              <li>
                <WppTag
                  label="These fields can be sorted and filtered."
                  maxLabelLength={65}
                />
              </li>
              <WppDivider />
              <li>
                <WppTag
                  label="These values have been updated for legacy assessments."
                  maxLabelLength={65}
                />
              </li>
              <WppDivider />
              <li>
                <WppTag
                  label="Invalid respondents have been removed from legacy assessments."
                  maxLabelLength={65}
                />
              </li>
              <WppDivider />
            <li>
                <WppTag
                  label="6-month limit for weekly assessment reminder emails."
                  maxLabelLength={65}
                />
              </li>
              <WppDivider />
              <li>
                <WppTag
                  label="Vendors and assessments can now be sorted by Created and Modified date."
                  maxLabelLength={65}
                />
              </li>
              <WppDivider />
              <li>
                <WppTag
                  label="Screenbase and Hudson Rouge added to domain mapping."
                  maxLabelLength={65}
                />
              </li>
              <WppDivider />
            <li>
                <WppTag
                  label="All users can see short summary of Vendors through Quick Summary section in Vendor Card screen."
                  maxLabelLength={65}
                />
              </li>
              <WppDivider />
              <li>
                <WppTag
                  label="GroupM template logic fix for question number 2.8."
                  maxLabelLength={65}
                />
              </li>
            <li>
                <WppTag
                  label="Fix of SU able to launch assessment and staying in Vendor flow only."
                  maxLabelLength={65}
                />
              </li>
              <WppDivider />
              <li>
                <WppTag
                  label="Drop down list showing items in alphabetical order."
                  maxLabelLength={65}
                />
              </li>
              <WppDivider />
            <li>
                <WppTag
                  label="Implemented input text length limit of 2048 characters for the following inputs: Note to Respondent, Approver Comment, Assessment Feedback, Vendor/Assessment Deletion Reason."
                  maxLabelLength={65}
                />
              </li>
              <WppDivider />
            <li>
                <WppTag
                  label="Assessment Portal will display survey progress percentage as per answered questions."
                  maxLabelLength={65}
                />
              </li>
              <WppDivider />
              <li>
                <WppTag
                  label="SDN UI will display Risk Flags at section level during review process to easily identify which section has risk scoring questions."
                  maxLabelLength={65}
                />
              </li>
              <WppDivider />
              <li>
                <WppTag
                  label="Standard Users and Data Privacy Managers cannot launch or relaunch assessments of different networks."
                  maxLabelLength={65}
                />
              </li>
              <WppDivider />
              <li>
                <WppTag
                  label="Assessments related to GroupM old template available in system now. This will be just at metadata level."
                  maxLabelLength={65}
                />
              </li>
              <WppDivider />
              <li>
                <WppTag label="Included respondents missing in few of the assessments." maxLabelLength={65} />
              </li>
              <WppDivider />
              <li>
                <WppTag label="Addition of Essencemedicom agency for GroupM." maxLabelLength={67} />
              </li>
              <WppDivider />
              <li>
                <WppTag
                  label="Fix for vendor Fast Moving Technology Co Ltd & Global Insight Conferences LTD."
                  maxLabelLength={65}
                />
              </li>
              <WppDivider />
              <li>
                <WppTag label="Fix in status of Vendor AdsWizz." maxLabelLength={65} />
              </li>
              <WppDivider />
              <li>
                <WppTag label="Fix for assessment submission issue." maxLabelLength={67} />
              </li>
              <WppDivider />
              <li>
                <WppTag label="Comments will be shown at section level during review process." maxLabelLength={67} />
              </li>
              <WppDivider />
              <li>
                <WppTag
                  label="Creators will not receive emails at the time of assessment launch."
                  maxLabelLength={67}
                />
              </li>
              <WppDivider />
              <li>
                <WppTag
                  label="Display of warning message at the time of assessment launch in case other Network template has been selected."
                  maxLabelLength={67}
                />
              </li>
              <WppDivider />
              <li>
                <WppTag
                  label="Standard Users can now delete Vendors that they have created themselves. This also deletes the associated assessment."
                  maxLabelLength={65}
                />
              </li>
              <WppDivider />
              <li>
                <WppTag
                  label="When deleting a Vendor record, the assessment respondents are now notified via email."
                  maxLabelLength={65}
                />
              </li>
              <WppDivider />
              <li>
                <WppTag
                  label="The Vendor table now displays all vendors from across all networks. This makes it easier to find vendors."
                  maxLabelLength={67}
                />
              </li>
              <WppDivider />
              <li>
                <WppTag label="Every table column can now be filtered for easier searching." maxLabelLength={67} />
              </li>
              <WppDivider />
              <li>
                <WppTag
                  label="Vendor and Assessment records that were created in OneTrust after 30th June have now been imported."
                  maxLabelLength={67}
                />
              </li>
              <WppDivider />
              <li>
                <WppTag
                  label="Respondent can upload multiple attachments for assessment questions."
                  maxLabelLength={60}
                />
              </li>
              <WppDivider />
              <li>
                <WppTag
                  label="Privacy managers can download all uploaded attachments during review process."
                  maxLabelLength={67}
                />
              </li>
              <WppDivider />
              <li>
                <WppTag
                  label="Review request email opens assessment card for the vendor to be reviewed."
                  maxLabelLength={67}
                />
              </li>
              <WppDivider />
              <li>
                <WppTag label="Agency mapping for EssenceMediacom in Canada." maxLabelLength={67} />
              </li>
              <WppDivider />
              <li>
                <WppTag label="Added Choreograph worldwide agency mapping." maxLabelLength={67} />
              </li>
              <WppDivider />
              <li>
                <WppTag label="Fixed Choreograph UK agency mapping to Choreograph network." maxLabelLength={67} />
              </li>
              <WppDivider />
              <li>
                <WppTag label="Added geosophymedia.com sign-in domain mapping." maxLabelLength={67} />
              </li>
              <WppDivider />
              <li>
                <WppTag
                  label="Keyade.com added as a user sign-in domain and mapped to the GroupM network."
                  maxLabelLength={60}
                />
              </li>
              <WppDivider />
              <li>
                <WppTag label="Added agencies to Worldwide for WPP orgs." maxLabelLength={67} />
              </li>
              <li>
                <WppTag label="New mapping for compas-inc.com." maxLabelLength={67} />
              </li>
              <WppDivider />
              <li>
                <WppTag label="Assessments can be relaunched through vendor screen." maxLabelLength={67} />
              </li>
              <li>
                <WppTag label="Relaunch Assessment " maxLabelLength={67} />
                <WppDivider />
                <ul>
                  <li>
                    <WppTag label="New mappings for user domains and Australian agencies. " maxLabelLength={67} />
                  </li>
                  <WppDivider />
                  <li>
                    <WppTag
                      label="Existing assessments that are in the Created or Response Pending state can now be relaunched. The vendor will be notified via email and prompted to continue the assessment."
                      maxLabelLength={60}
                    />
                  </li>
                  <WppDivider />
                  <li>
                    <WppTag
                      label="Any previously answered questions will be saved and restored in the relaunched assessment, so the vendor will not need to provide them again. "
                      maxLabelLength={60}
                    />
                  </li>
                  <WppDivider />
                  <li>
                    <WppTag
                      label="You can amend the respondents’ email addresses before relaunching. "
                      maxLabelLength={60}
                    />
                  </li>
                  <WppDivider />
                  <li>
                    <WppTag
                      label="Assessments that were launched in One Trust can now be relaunched in the SDN. "
                      maxLabelLength={60}
                    />
                  </li>
                </ul>
              </li>
              <li>
                <WppTag label="Edit vendor name. " maxLabelLength={67} />
              </li>
              <WppDivider />
              <li>
                <WppTag label="Delete vendor and assessment. " maxLabelLength={67} />
              </li>
              <WppDivider />
            </ul>
          </div>
        </WppCard>
      </div>
    </div>
  )
}