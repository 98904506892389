import { useEffect, useState } from 'react'

import { Grid } from '@mui/material'
import {
  WppButton,
  WppModal,
  WppIconClose,
  WppFileUpload,
  WppIconUpload,
  WppToast
} from '@wppopen/components-library-react'
import axios from 'axios'
import { useSelector } from 'react-redux'

import { serviceURL } from 'helper/serviceURL'

import BulkAuditTable from './BulkAuditTable'
import styles from './bulkRequestUpload.module.scss'
import appConfig from '../../../app.config'

const BulkRequestUpload = () => {
  const baseApiUrl = `${serviceURL.dpsBaseAPI}`
  const [showUploadModal, setShowUploadModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isRowsLoading, setIsRowsLoading] = useState(true)
  const [error, setError] = useState<{ name: string; message: string }>({ name: '', message: '' })
  const [showUploadSuccessToast, setShowUploadSuccessToast] = useState(false)
  const [rows, setRows] = useState([])
  const [selectedFile, setSelectedFile] = useState<File | null>(null)
  const userEmail = useSelector((state: any) => state?.getOrgByUserRed?.data)
  const headers = {
    Authorization: 'Basic ' + appConfig.DPS_TOKEN
  }
  const sanitizeFileName = (filename: string) => {
    return filename.replace(/\s+/g, '_').replace(/[^a-zA-Z0-9$!~\-_'.]/g, '')
  }
  const handleFileUploadChange = (event: any) => {
    setError({ name: '', message: '' })
    const file = event?.detail?.value[0]

    if (file) {
      const lastDotIndex = file.name.lastIndexOf('.')
      const originalFileName = file.name.substring(0, lastDotIndex)
      const extension = file.name.substring(lastDotIndex)
      const sanitizedFileName = sanitizeFileName(originalFileName) + extension
      setSelectedFile(new File([file], sanitizedFileName, { type: file.type }))
    } else {
      setSelectedFile(null)
    }
  }

  const handleClose = () => {
    setSelectedFile(null)
    setShowUploadModal(false)
    setError({ name: '', message: '' })
  }

  const bulkAuditRequest = async () => {
    const apiUrl = `${baseApiUrl}/dps/admin/dsar/bulk-audit`
    try {
      setIsRowsLoading(true)
      const response = await axios.get(apiUrl, { headers })
      setIsRowsLoading(false)
      setRows(response.data)
    } catch (error) {
      console.error('API Error:', error)
      setIsRowsLoading(false)
    }
  }

  const handleUpload = () => {
    setIsLoading(true)
    setError({ name: '', message: '' })
    const apiUrl = baseApiUrl + '/dps/admin/dsar/bulk-update'
    const formData = new FormData()
    const payload = {
      username: userEmail?.userEmail
    }
    formData.append('data', JSON.stringify(payload))

    if (selectedFile) {
      formData.append('file', selectedFile)
    } else {
      console.error('No file selected')
      setIsLoading(false)
      return
    }
    axios
      .post(apiUrl, formData, {
        headers
      })
      .then(async () => {
        setIsLoading(false)
        setShowUploadModal(false)
        setSelectedFile(null)
        setShowUploadSuccessToast(true)
        await bulkAuditRequest()
      })
      .catch(error => {
        setError({ name: error?.response?.data?.status || error?.name, message: error?.response?.data?.message || error?.message })
        setIsLoading(false)
      })
  }

  useEffect(() => {
    if (isRowsLoading) bulkAuditRequest()
  }, [])

  return (
    <div style={{ height: 700 }}>
      {showUploadSuccessToast && (
        <WppToast
          className={styles.toast}
          message="File uploaded successfully"
          type="success"
          duration={2000}
          onWppToastComplete={() => setShowUploadSuccessToast(false)}
          data-testid="toast-upload-success"
        />
      )}
      <div className={styles.bulkUploadContainer}>
        <WppButton className={styles.customBtnSecondary} variant="secondary" onClick={() => setShowUploadModal(true)}>
          <WppIconUpload slot="icon-start" className={styles.customIcon} />
          Upload Bulk Requests
        </WppButton>
        <p>
          Upload a CSV of request IDs that have been completed outside of the CPP.
          <br />
          These requests will be marked as 'complete', and confirmation emails will be sent to the affected data
          subjects.
        </p>
      </div>
      <Grid container spacing={{ xs: 2, md: 3, sm: 3 }} columns={{ xs: 4, sm: 8, md: 12 }} rowSpacing={10}>
        <Grid item xs={12} sm={12} md={12} style={{ margin: '2rem 5rem' }}>
          <BulkAuditTable rows={rows} isLoading={isRowsLoading} />
        </Grid>
      </Grid>
      {showUploadModal &&<WppModal
        open={showUploadModal}
        size="m"
        onWppModalClose={() => handleClose()}
        className={styles.uploadContainer}
        data-testid="bulk-file-upload"
      >
        <div slot="header" className={styles.uploadHeader}>
          <h3 className={styles.assessNameProgressContainer}>
            <span>Bulk Request Upload</span>
          </h3>
          <WppIconClose color="black" onClick={() => handleClose()} className={styles.close} size="m" />
        </div>
        <div slot="body">
          {error && error.message ? <p className={styles.errorMessage}>{error.message}</p> : null}
          <WppFileUpload
            onWppChange={handleFileUploadChange}
            acceptConfig={{
              'text/csv': ['.csv']
            }}
            className={styles.uploadFile}
            multiple={false}
            data-testid="file-upload-bulk"
            size={25}
          />
        </div>
        <div slot="actions" className={styles.btnClose}>
          <Grid item xs={12} sm={12} md={12} display="flex" gap={1} justifyContent="right">
            <WppButton
              className={styles.customBtnSecondary}
              variant="secondary"
              loading={isLoading}
              onClick={handleUpload}
              disabled={!selectedFile?.name?.trim().length}
              data-testid="file-upload-btn"
            >
              Upload
            </WppButton>
            <WppButton
              className={styles.customBtnSecondary}
              variant="secondary"
              onClick={() => handleClose()}
              data-testid="file-upload-cancel"
            >
              Cancel
            </WppButton>
          </Grid>
        </div>
      </WppModal>}
    </div>
  )
}

export default BulkRequestUpload
