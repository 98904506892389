import React from 'react'

import { WppButton } from '@wppopen/components-library-react'
import jsPDF from 'jspdf'

import 'jspdf-autotable'
import { QUE_TYPE } from 'helper/constants'
import { getLocalDate } from 'helper/Helper'

import styles from './pdf.module.scss'

interface SummaryProps {
  template: {
    logoUrl: string
  }
  inventoryType: {
    businessName: string
  }
  name: string
  description: string
  status: string
  createdBy: string
  createdAt: string
  modifiedAt: string
  reviewedBy: string
  reviewerStatus: string
  reviwerActionAt: string
  inventoryName: string
  respondents: { email: string }[]
}

interface DataProps {
  assessmentId: string;
  assessmentName?: string;
  isAllQnNonEmpty?: boolean;
  inventoryName: string|null;
  details: {
    categoryName: string
    questionAnswers: {
      question: {
        sequence: string
        description: string
        subDescription: string
        questionType: string
      }
      response: {
        responseValue: string
      }
      comments: {
        comment: string
        createdBy: string
        received: string
        reviewer: boolean
      }[]
      riskDetails: {
        riskLevel: string
      }
    }[]
  }[]
}
interface Content {
  type: string
  text?: string | undefined
  subDescription?: string
  values?: string[]
  additional?: string
  risk?: string
  reviewer?: boolean
  time?: string | undefined | null
  createdBy?: string
}
const generateContentForPDF = (data: DataProps) => {
  const content: Content[] = []
  data?.details?.forEach((category, index) => {
    if (category?.categoryName !== 'Data Privacy Team Only') {
      content.push({ type: 'title', text: `${index + 1}. ${category?.categoryName}` })

      category?.questionAnswers?.forEach(item => {
        content.push({
          type: 'question',
          text: `${item?.question?.sequence} ${item?.question?.description}`,
          subDescription: item?.question?.subDescription
        })

        if (
          [
            QUE_TYPE.SINGLE_SELECT,
            QUE_TYPE.SINGLE_SELECT_TEXT,
            QUE_TYPE.SINGLE_SELECT_TEXT_CONDITIONAL,
            QUE_TYPE.SINGLE_SELECT_TEXT_OPTIONAL
          ].includes(item.question.questionType)
        ) {
          const response = item?.response?.responseValue?.split('$$')
          content.push({
            type: 'response',
            text: response[0],
            additional: response[1],
            risk: item?.riskDetails?.riskLevel
          })
        }

        if (
          [
            QUE_TYPE.MULTI_SELECT,
            QUE_TYPE.MULTI_SELECT_TEXT,
            QUE_TYPE.MULTI_SELECT_TEXT_CONDITIONAL,
            QUE_TYPE.MULTI_SELECT_TEXT_OPTIONAL
          ].includes(item.question.questionType)
        ) {
          const response = item?.response?.responseValue?.split('$$')
          content.push({
            type: 'response-multi',
            values: response[0]?.split('|'),
            additional: response[1],
            risk: item?.riskDetails?.riskLevel
          })
        }

        if (item.question.questionType === 'TEXT') {
          content.push({
            type: 'text-response',
            text: item?.response?.responseValue,
            risk: item?.riskDetails?.riskLevel
          })
        }

        if (item?.comments?.length > 0) {
          content.push({ type: 'comments-header', text: 'Comments History' })
          item?.comments?.forEach(comment => {
            content.push({
              type: 'comment',
              reviewer: comment?.reviewer,
              text: comment?.comment,
              time: getLocalDate(comment?.received, true),
              createdBy: comment?.createdBy
            })
          })
        }
      })
    }
  })
  return content
}

export async function imageUrlToBase64(url: string): Promise<string> {
  try {
    const response = await fetch(url)
    if (!response.ok) throw new Error(`Failed to fetch image: ${response.statusText}`)
    const blob = await response.blob()

    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader()
      reader.onloadend = () => resolve(reader.result as string)
      reader.onerror = error => reject(error)
      reader.readAsDataURL(blob)
    })
  } catch (error) {
    console.error('Error converting image to Base64:', error)
    return Promise.reject(error)
  }
}

export const generatePDF = async (data: DataProps, summaryData: SummaryProps) => {
  const pdf = new jsPDF('p', 'mm', 'a4')
  const margin = 10
  const lineHeight = 10
  const pageWidth = pdf.internal.pageSize.getWidth()
  const usableWidth = pageWidth - margin * 2
  const pageHeight = pdf.internal.pageSize.getHeight()
  let yOffset = margin
  const logoBase64 = await imageUrlToBase64(summaryData?.template?.logoUrl)

  const logoY = 10 // Y-coordinate (margin from the top)
  const logoWidth = 70 // Width of the logo
  const logoHeight = 20 // Height of the logo
  const logoX = (pageWidth - logoWidth) / 2

  pdf.addImage(logoBase64, 'PNG', logoX, logoY, logoWidth, logoHeight)

  yOffset = logoY + logoHeight

  const content = generateContentForPDF(data)

  pdf.setFontSize(11)
  pdf.setTextColor(0, 0, 0)
  pdf.setFont('helvetica', 'bold')
  pdf.text(`Assessment Report - ${summaryData.name}`, margin, yOffset + 20)
  yOffset += 10

  pdf.setFontSize(9)
  pdf.setTextColor(0, 0, 0)
  pdf.setFont('helvetica')
  pdf.setTextColor(147, 64, 116)
  pdf.text('Vendor Details', margin, yOffset + 20)
  yOffset += 30

  const vendorheaderData = [['Name:', summaryData.inventoryName || 'N/A']]

  pdf.autoTable({
    body: vendorheaderData,
    startY: yOffset,
    theme: 'grid',
    bodyStyles: { fillColor: [255, 255, 255] },
    styles: { fontSize: 9, textColor: [0, 0, 0], overflow: 'linebreak' },
    columnStyles: {
      0: { fontStyle: 'bold', cellWidth: pageWidth * 0.3 },
      1: { textColor: [0, 0, 0], cellWidth: pageWidth * 0.6 }
    },
    tableWidth: 'auto',
    margin: { left: margin, right: margin }
  })

  pdf.autoTable({
    body: vendorheaderData,
    startY: yOffset,
    theme: 'grid',
    bodyStyles: { fillColor: [255, 255, 255] },
    styles: { fontSize: 9, textColor: [0, 0, 0], overflow: 'linebreak' },
    columnStyles: {
      0: { fontStyle: 'bold', cellWidth: pageWidth * 0.3 },
      1: { textColor: [0, 0, 0], cellWidth: pageWidth * 0.6 }
    },
    tableWidth: 'auto',
    margin: { left: margin, right: margin }
  })

  yOffset = (pdf as any).autoTable.previous.finalY

  pdf.setFontSize(9)
  pdf.setTextColor(0, 0, 0)
  pdf.setFont('helvetica')
  pdf.setTextColor(246, 183, 90)
  pdf.text('Assessment Details', margin, yOffset + 20)
  yOffset += 30

  const headerData = [
    ['Name:', summaryData.name || 'N/A'],
    ['Description:', summaryData.description || 'N/A'],
    ['Status:', summaryData.status],
    ['Created By:', summaryData.createdBy],
    ['Created At:', summaryData.createdAt ? getLocalDate(summaryData?.createdAt, true) : ''],
    ['Inventory Type:', summaryData.inventoryType?.businessName || ''],
    ['Last Activity Date:', summaryData?.modifiedAt ? getLocalDate(summaryData?.modifiedAt, true) : ''],
    ['Reviewed By:', summaryData?.reviewedBy || ''],
    ['Reviewer Action:', summaryData?.reviewerStatus || ''],
    ['Reviewed At:', getLocalDate(summaryData?.reviwerActionAt, true) ?? ''],
    [
      'Respondents:',
      summaryData?.respondents.length
        ? summaryData?.respondents.map((respondent: { email: string }) => respondent.email).join(', ')
        : 'N/A'
    ]
  ]

  pdf.autoTable({
    body: headerData,
    startY: yOffset,
    theme: 'grid',
    bodyStyles: { fillColor: [255, 255, 255] },
    styles: { fontSize: 9, textColor: [0, 0, 0], overflow: 'linebreak' },
    columnStyles: {
      0: { fontStyle: 'bold', cellWidth: pageWidth * 0.3 },
      1: { textColor: [0, 0, 0], cellWidth: pageWidth * 0.6 }
    },
    tableWidth: 'auto',
    margin: { left: margin, right: margin }
  })

  yOffset = (pdf as any).autoTable.previous.finalY + margin
  pdf.addPage()
  yOffset = margin

  content.forEach(item => {
    // Add title
    if (item.type === 'title') {
      pdf.setFont('helvetica', 'bold')
      pdf.setFontSize(11)
      pdf.setTextColor(21, 94, 149)
      const lines = item.text && pdf.splitTextToSize(item.text, usableWidth)
      pdf.text(lines, margin, yOffset)
      yOffset += lines.length * lineHeight
    }

    // Add questions
    else if (item.type === 'question') {
      pdf.setFont('helvetica', 'normal')
      pdf.setFontSize(10)
      pdf.setTextColor(0, 0, 0)
      const questionLines = item.text && pdf.splitTextToSize(item.text, usableWidth)
      pdf.text(questionLines, margin, yOffset)
      yOffset += item.subDescription ? (questionLines.length * lineHeight) / 2 : questionLines.length * lineHeight

      if (item.subDescription) {
        pdf.setFont('helvetica', 'italic')
        pdf.setFontSize(9)
        const subDescriptionLines = pdf.splitTextToSize(item.subDescription, usableWidth)
        pdf.text(subDescriptionLines, margin, yOffset)
        yOffset += (subDescriptionLines.length * lineHeight) / 2
      }
    }

    // Add responses
    else if (item.type === 'response') {
      if (item.risk) {
        pdf.setFont('helvetica', 'bold')
        pdf.setFontSize(9)

        const riskLabel = 'Risk: '
        const riskValue = item.risk

        pdf.setTextColor(0, 0, 0)
        pdf.text(riskLabel, margin, yOffset)

        const riskLabelWidth = pdf.getTextWidth(riskLabel)

        if (riskValue === 'VERY_HIGH') {
          pdf.setTextColor(141, 38, 35)
        } else if (riskValue === 'HIGH') {
          pdf.setTextColor(209, 96, 93)
        } else if (riskValue === 'MEDIUM') {
          pdf.setTextColor(246, 183, 90)
        } else if (riskValue === 'Low') {
          pdf.setTextColor(0, 128, 0)
        } else {
          pdf.setTextColor(204, 204, 204)
        }

        pdf.text(riskValue, margin + riskLabelWidth, yOffset)

        pdf.setTextColor(0, 0, 0)

        yOffset += lineHeight
      }

      pdf.setFont('helvetica', 'bold')
      pdf.setFontSize(9)
      pdf.setTextColor(21, 128, 61)
      const responseLabel = 'Response: '
      const responseValue = item.text

      pdf.text(responseLabel, margin, yOffset)

      const responseLabelWidth = pdf.getTextWidth(responseLabel)

      pdf.setFont('helvetica', 'normal')
      pdf.setFontSize(9)
      pdf.setTextColor(0, 0, 0)
      const responseLines = pdf.splitTextToSize(`${responseValue}`, usableWidth)
      pdf.text(responseLines, margin + responseLabelWidth, yOffset)
      yOffset += lineHeight / 2
      // yOffset += item.additional ? responseLines.length * lineHeight/2 : responseLines.length * lineHeight;

      if (item.additional) {
        pdf.setFont('helvetica', 'bold')
        pdf.setFontSize(9)

        yOffset += lineHeight / 2

        pdf.setFont('helvetica', 'italic')
        const additionalTextLines = pdf.splitTextToSize(item.additional, usableWidth)
        const additionalTextHeight = additionalTextLines.length * lineHeight + 4
        pdf.rect(margin, yOffset, usableWidth, additionalTextHeight)
        pdf.text(additionalTextLines, margin + 2, yOffset + 4)

        yOffset += additionalTextHeight + 10
      }
    }

    // Add multi-select responses
    else if (item.type === 'response-multi') {
      if (item.risk) {
        pdf.setFont('helvetica', 'bold')
        pdf.setFontSize(9)

        const riskLabel = 'Risk: '
        const riskValue = item.risk
        pdf.setTextColor(0, 0, 0)
        pdf.text(riskLabel, margin, yOffset)

        const riskLabelWidth = pdf.getTextWidth(riskLabel)

        if (riskValue === 'VERY_HIGH') {
          pdf.setTextColor(141, 38, 35)
        } else if (riskValue === 'HIGH') {
          pdf.setTextColor(209, 96, 93)
        } else if (riskValue === 'MEDIUM') {
          pdf.setTextColor(246, 183, 90)
        } else if (riskValue === 'LOW') {
          pdf.setTextColor(0, 128, 0)
        } else {
          pdf.setTextColor(204, 204, 204)
        }

        pdf.text(riskValue, margin + riskLabelWidth, yOffset)

        pdf.setTextColor(0, 0, 0)
        yOffset += lineHeight
      }

      pdf.setFont('helvetica', 'bold')
      pdf.setFontSize(9)
      pdf.setTextColor(21, 128, 61)
      pdf.text('Response:', margin, yOffset)
      yOffset += lineHeight
      item?.values &&
        item.values.forEach(value => {
          pdf.setFont('helvetica', 'normal')
          pdf.setTextColor(0, 0, 0)
          const valueLines = pdf.splitTextToSize(`• ${value}`, usableWidth)
          pdf.text(valueLines, margin + 5, yOffset)
          yOffset += valueLines.length * lineHeight
        })

      if (item.additional) {
        pdf.setFont('helvetica', 'bold')
        pdf.setFontSize(9)

        yOffset += lineHeight / 2

        pdf.setFont('helvetica', 'italic')
        const additionalTextLines = pdf.splitTextToSize(item.additional, usableWidth)
        const additionalTextHeight = additionalTextLines.length * lineHeight + 4
        const horizontalPadding = 2
        const borderWidth = usableWidth - 2 * horizontalPadding

        pdf.rect(margin, yOffset, usableWidth, additionalTextHeight)

        pdf.text(additionalTextLines, margin + horizontalPadding, yOffset + 4, { maxWidth: borderWidth })

        yOffset += additionalTextHeight + 10
      }
    } else if (item.type === 'text-response') {
      pdf.setFont('helvetica', 'bold')
      pdf.setFontSize(9)
      pdf.setTextColor(21, 128, 61)
      pdf.text('Response:', margin, yOffset)
      yOffset += lineHeight / 2

      pdf.setFont('helvetica', 'italic')
      pdf.setTextColor(0, 0, 0)
      const textLines = item?.text && pdf.splitTextToSize(item?.text, usableWidth)
      const textHeight = textLines.length * lineHeight + 4

      pdf.rect(margin, yOffset, usableWidth, textHeight)

      pdf.text(textLines, margin + 2, yOffset + 4)

      yOffset += textHeight + 15
    }

    // Add comments
    else if (item.type === 'comments-header') {
      pdf.setFont('helvetica', 'bold')
      pdf.setFontSize(9)
      const headerLines = item.text && pdf.splitTextToSize(item.text, usableWidth)
      pdf.setTextColor(2, 136, 209)
      pdf.text(headerLines, margin, yOffset)
      yOffset += headerLines.length * lineHeight
    } else if (item.type === 'comment') {
      pdf.setFont('helvetica', 'bold')
      pdf.setFontSize(9)

      if (item.reviewer) {
        const reviewerLines = pdf.splitTextToSize(`Reviewer (${item?.createdBy})`, usableWidth)
        pdf.setTextColor(246, 183, 90)
        pdf.text(reviewerLines, margin, yOffset)
        yOffset += (reviewerLines.length * lineHeight) / 2

        const timeLabel = 'Time: '
        const timeValue = item?.time

        pdf.setTextColor(0, 0, 0)
        pdf.text(timeLabel, margin, yOffset)

        const timeLabelWidth = pdf.getTextWidth(timeLabel)
        pdf.setTextColor(21, 128, 61)

        timeValue && pdf.text(timeValue, margin + timeLabelWidth, yOffset)
        yOffset += lineHeight / 2
      } else {
        const reviewerLines = pdf.splitTextToSize('Vendor', usableWidth)
        pdf.setTextColor(147, 64, 116)
        pdf.text(reviewerLines, margin, yOffset) // Add text
        yOffset += (reviewerLines.length * lineHeight) / 2

        const timeLabel = 'Time: '
        const timeValue = item?.time

        pdf.setTextColor(0, 0, 0)
        pdf.text(timeLabel, margin, yOffset)

        const timeLabelWidth = pdf.getTextWidth(timeLabel)
        pdf.setTextColor(21, 128, 61)

        timeValue && pdf.text(timeValue, margin + timeLabelWidth, yOffset)
        yOffset += lineHeight / 2
      }
      pdf.setFont('helvetica', 'normal')
      pdf.setFontSize(9)
      pdf.setTextColor(0, 0, 0)
      const commentLines = item.text && pdf.splitTextToSize(item.text, usableWidth)
      pdf.text(commentLines, margin, yOffset)
      yOffset += commentLines.length < 3 ? commentLines.length * lineHeight : (commentLines.length * lineHeight) / 2
    }

    if (yOffset + lineHeight >= pageHeight - margin) {
      pdf.addPage()
      yOffset = margin
    }
  })

  const fileName = summaryData?.name
    ? `${summaryData.name.toLowerCase().trim()}full_assessment_report.pdf`
    : 'full_assessment_report.pdf'

  pdf.save(fileName) // Save the PDF
}

const FullAssessmentPdf = ({ data, summaryData }: { data: DataProps; summaryData: SummaryProps }) => {
  return (
    <WppButton
      className={styles.pdfBtnAssesment}
      variant="secondary"
      onClick={() => generatePDF(data, summaryData)}
      data-testid="assessment-export-pdf"
    >
      Export Full Assessment
    </WppButton>
  )
}

export default FullAssessmentPdf
