import React, { memo, useCallback, useEffect, useLayoutEffect, useState } from 'react'

import ErrorIcon from '@mui/icons-material/Error';
import { Container, Grid } from '@mui/material'
import {
  WppBanner,
  WppProgressIndicator,
  WppSideModal,
  WppTypography,
  WppTag,
  WppButton,
  WppIconAdd,
  WppIconClose,
  WppActionButton,
  WppModal,
  WppToast,
} from '@wppopen/components-library-react'
import { useOs } from '@wppopen/react'
import { connect } from 'react-redux'

import DataGridOfflineComp from 'components/dataGrid/DataGridOfflineComp'
import FullAssessmentPdf from 'components/pdf/fullAssessmentPdf';
import TextArea from 'components/textArea/TextArea'
import { setTriggerReload } from 'containers/tprmModule/vendorsList/rowDetails/action'
import TagTotalRiskIndicator from 'helper/TagTotalRiskIndicator'

import { clearAssessmentByIdDispatcher, getAssessmentByIdDispatcher } from './action'
import styles from './RowDetails.module.scss'
import appConfig from '../../../../app.config'
import AssessmentSummary from '../../../../components/assessmentSummary/AssessmentSummary'
import AssessmentPdf from '../../../../components/pdf/assessmentPdf'
import QuesAnsAssessment from '../../../../components/queAnsAsssessment/QuesAnsAssessment'
import { ASSESS_PROGRESS_STATUS, STATUS, USER_ROLE, BAD_REQUEST } from '../../../../helper/constants'
import { prepareAssessmentDataHelper, getLocalDate } from '../../../../helper/Helper'
import { ProgressStatus } from '../../../../helper/interface'
import serviceURL from '../../../../helper/serviceURL'
import TagStatusCategoryIndicator from '../../../../helper/TagStatusCategoryIndicator'
import useAxiosInterceptors from '../../../../hooks/useAxiosInterceptors'
import RelaunchAssessment from '../createAssessment/RelaunchAssessment'
import { AssessAccordItem } from '../interface'

const mapDispatchToProps = (dispatch: any) => ({
  getAssessmentByIdDispatcher: (id: string, head: object) => dispatch(getAssessmentByIdDispatcher(id, head)),
  clearAssessmentByIdDispatcher: (invAssetRes: object) => dispatch(clearAssessmentByIdDispatcher(invAssetRes)),
  setTriggerReloadDispatcher: (value: boolean) => dispatch(setTriggerReload(value))
})

const mapStateToProps = (state: any) => {
  return {
    assessRowDetails: state.assessmentRowDetailsRed.data,
    inventoryByAssessDetails: state.inventoryDetailsViaAssessRed.data,
    selectedSideOrg: state.selectedSideNavOrgRed.data,
    getOrgByUserRed: state.getOrgByUserRed.data,
    userContext: state.getOrgByUserRed.context,
    isTriggerReload: state?.triggerReload?.data,
  }
}

export interface RowDetailsModalProps {
  clickedRowData?: {
    id: string
    name: string
    description?: string
    inventoryId?: string
    inventoryType?: any
    categories?: any
    status?: boolean
  }
  open: boolean
  onClose: () => void
  getAssessmentByIdDispatcher?: (id: string, head: object) => Promise<any>
  clearAssessmentByIdDispatcher?: (invAssetRes: object) => Promise<any>
  selectedSideOrg?: {
    id: number
    name: string
  }
  getOrgByUserRed: any
  assessRowDetails?: any
  showApproveToast?: (val: boolean) => void
  closeSideModal?: () => void
  setTriggerReloadDispatcher: (val: boolean) => void
  userContext?: { id: number; email: string }
  isTriggerReload?: boolean
}

const RowDetails = memo(
  ({
    open,
    onClose,
    clickedRowData,
    getAssessmentByIdDispatcher,
    clearAssessmentByIdDispatcher,
    assessRowDetails,
    selectedSideOrg,
    getOrgByUserRed,
    showApproveToast,
    closeSideModal,
    setTriggerReloadDispatcher,
    userContext,
    isTriggerReload
  }: RowDetailsModalProps) => {
    const [isLoading, setIsLoading] = useState(false)
    const [isToShowBanner, setIsToShowBanner] = useState(false)
    const [deleteMessage, setDeleteMessage] = useState('')
    const [showDeleteToast, setShowDeleteToast] = useState(false)
    const [showDeleteErrorToast, setShowDeleteErrorToast] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [accordAssessObj, setAccordAssessObj] = useState<AssessAccordItem>({
      assessmentId: '1',
      assessmentName: '',
      details: [],
      inventoryName: null,
      isAllQnNonEmpty: false
    })
    const invAssetRes = { asessDetails: null, invDetails: null, hasError: false }
    const {
      osApi: { getAccessToken }
    } = useOs()
    const { axiosInstance } = useAxiosInterceptors()
    const headers = {
      accept: '*/*',
      Authorization: 'Bearer ' + getAccessToken()
    }
    const [progressValue, setProgressValue] = useState(0)
    const [reviewerCommentLoaded, setReviewerCommentLoaded] = useState(null)
    const [relaunchModal, setRelaunchModal] = useState(false)
    const isSAD = getOrgByUserRed?.groups?.some((item: any) => [USER_ROLE.SAD].includes(item.name))
    const isDPM = getOrgByUserRed?.groups?.some((item: any) => [USER_ROLE.DPM].includes(item.name))
    const isSU = getOrgByUserRed?.groups?.some((item: any) => [USER_ROLE.SU].includes(item.name))
    const isSelfCreated =
      assessRowDetails?.createdBy?.toLowerCase()?.trim() === userContext?.email?.toLowerCase()?.trim()
    const hasOwnNetwork = assessRowDetails?.agency?.organization?.id === userContext?.id
    const progressCalculator = (list: ProgressStatus[], val: string) => {
      for (const [, listVal] of Object.entries(list)) {
        if (listVal['name'] === val) {
          return listVal['value']
        }
      }
      return 0
    }
    const executeCallback = useCallback(
      (val: any) => {
        setReviewerCommentLoaded(val)
      },
      [reviewerCommentLoaded]
    )
    useEffect(() => {
      if (assessRowDetails && assessRowDetails.status) {
        setProgressValue(progressCalculator(ASSESS_PROGRESS_STATUS, assessRowDetails.status))
      }
    }, [assessRowDetails])

    useLayoutEffect(() => {
      fetchAssessmentById()
      return () => {
        clearAssessmentByIdDispatcher &&
          clearAssessmentByIdDispatcher(invAssetRes)
            .then(() => {
              console.log('clearing row details modal')
              setTriggerReloadDispatcher(false)
            })
            .catch(err => {
              console.log(err)
            })
      }
    }, [])

    const fetchAssessmentById = () => {
      if (clickedRowData) {
        setIsLoading(true)
        getAssessmentByIdDispatcher &&
          getAssessmentByIdDispatcher(clickedRowData.id, headers)
            .then(res => {
              setIsLoading(false)
              prepareAssessmentData(res)
            })
            .catch(err => {
              console.log(err)
              setIsLoading(false)
              setIsToShowBanner(true)
            })
      }
    }

    useEffect(() => {
      isTriggerReload && setTriggerReloadDispatcher(false)
    }, [isTriggerReload])

    const prepareAssessmentData = (data: any) => {
      const _assessment = prepareAssessmentDataHelper(data, getOrgByUserRed.groups, selectedSideOrg, getOrgByUserRed)
      setAccordAssessObj(_assessment)
    }

    const handleRelaunchAssessment = () => {
      setRelaunchModal(true)
    }

    const handleDelete = () => {
      try {
        const apiUrl = serviceURL.pgpBaseAPI + '/api/assessments/' + clickedRowData?.id + '/delete'
        const payload = {
          remarks: deleteMessage || ''
        }
        axiosInstance
          .put(apiUrl, payload, {
            headers: headers
          })
          .then(() => {
            setShowDeleteToast(true)
            setShowDeleteModal(false)
            setTriggerReloadDispatcher(true)
            setTimeout(() => {
              onClose()
            }, 2000)
          })
          .catch(error => {
            setShowDeleteToast(false)
            setShowDeleteErrorToast(true)
            console.log(error)
          })
      } catch (e) {
        console.log(e)
      }
    }

    const handleChange = (event: any) => {
      setDeleteMessage(event?.target?.value)
    }

    const isEligibleForRelaunch = () => {
      const isStatusNotReviewPending =
        assessRowDetails?.status?.toLowerCase().trim() !== STATUS.REVIEW_PENDING?.toLowerCase().trim()

      if (appConfig.CUTOFF_DATE !== 'NA') {
        const launchDate = new Date(assessRowDetails?.createdAt)
        const cutOffDate = new Date(appConfig.CUTOFF_DATE)
        const isBefore = launchDate < cutOffDate
        if (isStatusNotReviewPending && isBefore) {
          return checkRoleWisePermission()
        }
      } 
      if (isStatusNotReviewPending) {
        return checkRoleWisePermission();
      }
    
      return false;
    }

    const checkRoleWisePermission = () => {
      if (isSAD || (isDPM && hasOwnNetwork) || (isSU && isSelfCreated && hasOwnNetwork)) {
        return true
      }
      return false
    }

    const hasRequiredPermission = () => {
      if (isSAD || (isDPM && hasOwnNetwork)) {
        return true
      }
      return false
    }

    return (
      <>
        <WppSideModal open={open} size="2xl" onWppSideModalClose={onClose} className={styles.modalBox}>
          {isLoading ? (
            <div slot="body">
              <DataGridOfflineComp />
            </div>
          ) : (
            assessRowDetails?.status === BAD_REQUEST ?
            <>
            <h3 slot="header" className={styles.assessNameProgressContainer}>
            <span className={styles.deleteAssessmentHeader}>This assessment has been deleted.</span>
              <div className={styles.buttonAction}>
                <WppActionButton onClick={onClose} variant="secondary" slot="actions">
                  <WppIconClose className={styles.close} />
                </WppActionButton>
              </div>
            </h3>

            <div slot="body" style={{ marginTop: '1.5rem' }}>
              {assessRowDetails && (
                <Container maxWidth="xl">
                  <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }} rowSpacing={10}>
                    <Grid item xs={12} sm={12} md={12}>
                      <div className={styles.deleteAssessment}>
                        <span className={styles.deleteAssessmentLabel}>Assessment Name:</span>
                        <span className={styles.deleteAssessmentValue}>{assessRowDetails?.metaData?.name}</span>
                      </div>
                      <div className={styles.deleteAssessment}>
                        <span className={styles.deleteAssessmentLabel}>Reason for Deletion:</span>
                        <span className={styles.deleteAssessmentValue}>{assessRowDetails?.message}</span>
                      </div>

                      <ErrorIcon className={styles.errorIcon} />
                    </Grid>
                  </Grid>
                </Container>
              )}
            </div>
            </>
            :
            <>
              <h3 slot="header" className={styles.assessNameProgressContainer}>
                <span className={styles.header}>{clickedRowData && clickedRowData.name}</span>
                <div className={styles.buttonAction}>
                  <WppActionButton onClick={onClose} variant="secondary" slot="actions">
                    <WppIconClose className={styles.close} />
                  </WppActionButton>
                </div>
                {showDeleteToast && (
                  <WppToast
                    className={styles.toast}
                    message="Assessment deleted successfully"
                    type="success"
                    duration={2000}
                    onWppToastComplete={() => setShowDeleteToast(false)}
                  />
                )}
                {showDeleteErrorToast && (
                  <WppToast
                    className={styles.toast}
                    message="Vendor delete has errors"
                    type="error"
                    duration={2000}
                    onWppToastComplete={() => setShowDeleteErrorToast(false)}
                  />
                )}
              </h3>

              <div slot="body" style={{ marginTop: '1.5rem' }}>
                {assessRowDetails.status && (
                  <div className={styles.progressContainer}>
                    <div>
                      <div>Review process {progressValue * 100 + '%'}</div>
                      <progress className={styles.progressBar} value={progressValue} />
                    </div>
                    <div className={styles.headerButtons}>
                      {isEligibleForRelaunch() && (
                        <div>
                          <WppButton
                            className={styles.customBtnSecondary}
                            variant="secondary"
                            size="s"
                            onClick={handleRelaunchAssessment}
                          >
                            <WppIconAdd slot="icon-start" className={styles.customIcon} />
                            Relaunch Assessment
                          </WppButton>
                        </div>
                      )}
                      {checkRoleWisePermission() && (
                      <div className={styles.assessmentButton}>
                        <WppButton
                          className={styles.customBtnSecondary}
                          variant="secondary"
                          size="s"
                          onClick={() => setShowDeleteModal(true)}
                        >
                          Delete Assessment
                        </WppButton>
                      </div>)}
                      <div>
                        {hasRequiredPermission() && (
                          <AssessmentPdf data={assessRowDetails} isLoading={isLoading} />
                        )}
                      </div>
                      <div style={{marginLeft: '10px'}}>
                      {hasRequiredPermission() && (
                          <FullAssessmentPdf data={accordAssessObj} summaryData={assessRowDetails} />
                        )}
                        </div>
                    </div>
                  </div>
                )}
                {isLoading && (
                  <div style={{ marginBottom: '1rem' }}>
                    <WppProgressIndicator className={styles.customLoader} variant="bar" isShowPercentage={false} />
                  </div>
                )}

                <div style={{ marginBottom: '1rem' }}>
                  <WppBanner id="banner" type="information" show={isToShowBanner} className={styles.banner}>
                    Unable to fetch information. Please refresh or try after some time.
                  </WppBanner>
                </div>
                {assessRowDetails && (
                  <Container maxWidth="xl">
                    <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }} rowSpacing={10}>
                      <Grid item xs={12} sm={12} md={12}>
                        <div className={styles.secInventoryDetailsTop}>
                          <div className={styles.headerContainer}>
                            <div className={styles.leftContainer}>
                            <div className={styles.headerBox}>
                                <span className={styles.key}>Vendor: </span>
                                <span className={styles.value}>
                                  {assessRowDetails?.inventoryName ? (
                                    <WppTag
                                      label={assessRowDetails?.inventoryName}
                                      className={styles.tag}
                                      maxLabelLength={40}
                                      tooltipConfig={{ placement: 'bottom' }}
                                    />
                                  ) : (
                                    ''
                                  )}
                                </span>
                              </div>
                              <div className={styles.headerBox}>
                                <span className={styles.key}>Agency: </span>
                                <span className={styles.value}>
                                  {assessRowDetails?.agency?.name ? (
                                    <WppTag
                                      label={assessRowDetails?.agency.name}
                                      className={styles.tag}
                                      maxLabelLength={40}
                                      tooltipConfig={{ placement: 'bottom' }}
                                    />
                                  ) : (
                                    ''
                                  )}
                                </span>
                              </div>
                              <div className={styles.headerBox}>
                                <span className={styles.key}>Market: </span>
                                <span className={styles.value}>
                                  {assessRowDetails?.agency?.market?.name ? (
                                    <WppTag
                                      label={assessRowDetails?.agency?.market?.name}
                                      className={styles.tag}
                                      maxLabelLength={40}
                                      tooltipConfig={{ placement: 'bottom' }}
                                    />
                                  ) : (
                                    ''
                                  )}
                                </span>
                              </div>
                              <div className={styles.headerBox}>
                                <span className={styles.key}>Template: </span>
                                <span className={styles.value}>
                                  {assessRowDetails?.template?.name ? (
                                    <WppTag
                                      label={assessRowDetails?.template?.name}
                                      className={styles.tag}
                                      maxLabelLength={40}
                                      tooltipConfig={{ placement: 'bottom' }}
                                    />
                                  ) : (
                                    ''
                                  )}
                                </span>
                              </div>
                              <div className={styles.headerBox}>
                                <span className={styles.key}>Description: </span>
                                <span className={styles.value}>
                                  {assessRowDetails && assessRowDetails.description ? (
                                    <WppTag
                                      label={assessRowDetails.description}
                                      className={styles.tag}
                                      maxLabelLength={40}
                                      tooltipConfig={{ placement: 'bottom' }}
                                    />
                                  ) : (
                                    ''
                                  )}
                                </span>
                              </div>
                              <div className={styles.headerBox}>
                                <span className={styles.key}>Status: </span>
                                {assessRowDetails.status && (
                                  <TagStatusCategoryIndicator
                                    params={{ value: assessRowDetails?.status }}
                                    className={styles.customTag}
                                  />
                                )}
                              </div>
                              {hasRequiredPermission() && (
                                <div className={styles.headerBox}>
                                  <span className={styles.key}>Risk Score: </span>
                                  {assessRowDetails?.riskScore !== null && assessRowDetails?.riskScore !== undefined &&(
                                    <TagTotalRiskIndicator
                                      data={assessRowDetails}
                                      value={assessRowDetails?.riskScore}
                                    />
                                  )}
                                </div>
                              )}
                            </div>
                            <div className={styles.rightContainer}>
                              <div className={styles.headerBox}>
                                <span className={styles.key}>Respondents: </span>
                                {assessRowDetails?.respondents && assessRowDetails?.respondents.length ? (
                                  <span className={styles.respondedContainer}>
                                    <WppTag
                                      label={assessRowDetails?.respondents
                                        ?.map((m: { email: string }) => m?.email)
                                        .join(', ')}
                                      className={styles.tag}
                                      maxLabelLength={40}
                                      tooltipConfig={{ placement: 'bottom' }}
                                    />
                                  </span>
                                ) : (
                                  ''
                                )}
                              </div>
                              <div className={styles.headerBox}>
                                <span className={styles.key}>Created By: </span>
                                <span className={styles.value}>
                                  {assessRowDetails && assessRowDetails?.createdBy ? (
                                    <WppTag
                                      label={assessRowDetails?.createdBy}
                                      className={styles.tag}
                                      maxLabelLength={40}
                                      tooltipConfig={{ placement: 'bottom' }}
                                    />
                                  ) : (
                                    ''
                                  )}
                                </span>
                              </div>
                              <div className={styles.headerBox}>
                                <span className={styles.key}>Created At: </span>
                                <span>{assessRowDetails?.createdAt && getLocalDate(assessRowDetails?.createdAt, true)}</span>
                              </div>
                              <div className={styles.headerBox}>
                                <span className={styles.key}>Inventory Type: </span>
                                <span>{assessRowDetails && assessRowDetails.inventoryType?.businessName}</span>
                              </div>
                              <div className={styles.headerBox}>
                                <span className={styles.key}>Last Activity Date: </span>
                                <span>
                                  {assessRowDetails?.modifiedAt && getLocalDate(assessRowDetails?.modifiedAt, true)}
                                </span>
                              </div>
                              <div className={styles.headerBox}>
                                <span className={styles.key}>Reviewed By: </span>
                                <span>{assessRowDetails && assessRowDetails?.reviewedBy}</span>
                              </div>
                              <div className={styles.headerBox}>
                                <span className={styles.key}>Reviewer Action: </span>
                                {assessRowDetails?.reviewerStatus ? <TagStatusCategoryIndicator
                                    params={{ value: assessRowDetails?.reviewerStatus }}
                                    className={styles.customTag}
                                  /> : ''}
                              </div>
                              <div className={styles.headerBox}>
                                <span className={styles.key}>Reviewed At: </span>
                                <span>{assessRowDetails && getLocalDate(assessRowDetails?.reviwerActionAt, true)}</span>
                              </div>
                            </div>
                          </div>
                          <Grid className={styles.flowChart} item xs={12} sm={12} md={12}>
                            <AssessmentSummary
                              assessRowDetails={assessRowDetails}
                              callBackDataLoaded={executeCallback}
                              assessmentRiskCountDetails={accordAssessObj}
                              reviewerCommentLoaded={reviewerCommentLoaded}
                            />
                          </Grid>
                        </div>
                      </Grid>

                      <Grid item xs={12} sm={12} md={12} paddingTop="2rem !important" paddingBottom="5rem !important">
                        <div className={styles.tabSection}>
                          <WppTypography className={styles.tabContent} id="queAndAnswers">
                            {!isLoading && (
                              <QuesAnsAssessment
                                assessment={accordAssessObj}
                                showApproveToast={showApproveToast}
                                closeSideModal={closeSideModal}
                                prepareAssessmentData={prepareAssessmentData}
                                apiTriggger={() => setTriggerReloadDispatcher(true)}
                              />
                            )}
                          </WppTypography>
                        </div>
                      </Grid>
                    </Grid>
                  </Container>
                )}
              </div>
            </>
          )}
        </WppSideModal>
        {relaunchModal && (
          <RelaunchAssessment
            relaunchModal={relaunchModal}
            onClose={onClose}
            assessRowDetails={assessRowDetails}
            setTriggerReloadDispatcher={() => setTriggerReloadDispatcher(true)}
          />
        )}
        <WppModal open={showDeleteModal} size="s" onWppModalClose={onClose}>
          <div slot="header" className={styles.deleteHeader}>
            <h3 className={styles.assessNameProgressContainerDelete}>
              <span>Delete Assessment Record</span>
            </h3>
            <WppIconClose color="black" onClick={() => setShowDeleteModal(false)} className={styles.close} size="m" />
          </div>
          <div slot="body" className={styles.relaunchModalBody}>
            <span>
            This assessment record, including any responses, will be deleted. If the assessment is pending, the creator will be notified via email.
            </span>
            <TextArea
              name="deleteMessage"
              placeholder="Reason for deletion..."
              className={styles.reviewContainerCommentItem}
              value={deleteMessage}
              onChange={e => handleChange(e)}
            />
          </div>
          <div slot="actions" className={styles.btnClose}>
            <Grid item xs={12} sm={12} md={12} display="flex" gap={1} justifyContent="right">
              <WppButton
                className={styles.customBtnSecondary}
                variant="secondary"
                loading={false}
                onClick={handleDelete}
                disabled={!deleteMessage.trim().length}
              >
                DELETE
              </WppButton>
              <WppButton
                className={styles.customBtnSecondary}
                variant="secondary"
                loading={false}
                onClick={() => setShowDeleteModal(false)}
              >
                Cancel
              </WppButton>
            </Grid>
          </div>
        </WppModal>
      </>
    )
  }
)

export default connect(mapStateToProps, mapDispatchToProps)(RowDetails)