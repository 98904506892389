import React, { useState, useEffect, ChangeEvent } from 'react'

import { Grid, Container } from '@mui/material'
import {
  WppListItem,
  WppSelect,
  WppInput,
  WppButton,
  WppBanner,
  WppLabel,
  WppModal,
  WppIconClose,
  WppToast,
} from '@wppopen/components-library-react'
import { useOs } from '@wppopen/react'
import { connect } from 'react-redux'

import EmailRespondent from 'components/emailRespondent/EmailRespondent'
import TextArea from 'components/textArea/TextArea'

import { RelaunchAssessmentProps, ValidationError, RespondentValidationError } from './interface'
import {
  selectedRespondentDispatcher,
  assessNameDispatcher
} from './step3/action'
import { INVENTORY } from '../../../../helper/constants'
import { serviceURL } from '../../../../helper/serviceURL'
import useAxiosInterceptors from '../../../../hooks/useAxiosInterceptors'
import styles from '../createAssessment/step3/Step3.module.scss'

const mapStateToProps = (state: any) => {
  return {
    selectedType: state.caSelectedTypeReducer.data,
    selectedSideOrg: state.selectedSideNavOrgRed.data,
    selectedInv: state.caSelectedInvReducer.data,
    selectedMarket: state.caSelectedMarketReducer.data,
    selectedAgency: state.caSelectedAgencyReducer.data,
    selectedRegion: state.caSelectedRegReducer.data,
    userData: state.getOrgByUserRed.data
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  assessNameDispatcher: (name: string) => dispatch(assessNameDispatcher(name)),
  selectedRespondentDispatcher: (respondent: object) => dispatch(selectedRespondentDispatcher(respondent))
})

const RelaunchAssessment = ({
  relaunchModal,
  onClose,
  assessRowDetails,
  selectedType,
  selectedSideOrg,
  selectedInv,
  selectedMarket,
  selectedAgency,
  selectedRegion,
  assessNameDispatcher,
  userData,
  setTriggerReloadDispatcher
}: RelaunchAssessmentProps) => {
  const [isToShowBanner, setIsToShowBanner] = useState(false)
  const daysOptions = Array.from({ length: 60 }, (_, index) => index + 1)
  const [showSpinner, setShowSpinner] = useState(false)
  const { axiosInstance } = useAxiosInterceptors()
  const [bannerMsg, setBannerMsg] = useState('')
  const [respondentValidationErrors, setRespondentValidationErrors] = useState<RespondentValidationError[]>([])
  const [respondentEmail, setRespondentEmail] = useState<{ id: number; fullname: string; email: string }[]>([{ id: Date.now(), fullname: '', email: '' }])
  const [description, setDescription] = useState('')
  const [daysToRespond, setDaysToRespond] = useState(0)
  const [showRelaunchToast, setShowRelaunchToast] = useState(false)
  const {
    osApi: { getAccessToken }
  } = useOs()
  const headers = {
    accept: '*/*',
    Authorization: 'Bearer ' + getAccessToken()
  }

  useEffect(() => {
    if(assessRowDetails?.respondents?.length > 0) {
      setRespondentEmail(assessRowDetails?.respondents)
    }
    setDescription(assessRowDetails?.description || '')
    setDaysToRespond(assessRowDetails?.daysToRespond)
    switch (selectedType?.name) {
      case INVENTORY.TYPE_VE:
        assessNameDispatcher &&
          assessNameDispatcher(selectedInv?.name + ' | ' + (selectedMarket?.name || selectedInv?.market?.name))
        break
      case INVENTORY.TYPE_PA:
        assessNameDispatcher &&
          assessNameDispatcher(
            selectedInv?.name +
              ' | ' +
              selectedSideOrg?.name +
              ' | ' +
              (selectedMarket?.name || selectedInv?.market?.name) +
              ' | ' +
              (selectedAgency?.name || selectedInv?.agency?.name)
          )
        break
      case INVENTORY.TYPE_DA:
        assessNameDispatcher &&
          assessNameDispatcher(
            selectedInv?.name +
              ' | ' +
              (selectedRegion?.name || selectedInv?.region?.name) +
              ' | ' +
              (selectedMarket?.name || selectedInv?.market?.name) +
              ' | ' +
              selectedSideOrg?.name
          )
        break
    }
  }, [])

  const validateRespondents = (): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const errors: ValidationError[] = []
    respondentEmail.forEach(respondent => {
      if (!respondent?.fullname) {
        errors.push({ name: `fullname-${respondent.id}`, error: 'Name is required.' })
      }
      if (respondent?.fullname?.length > 50) {
        errors.push({ name: `fullname-${respondent.id}`, error: 'Max limit is 50 characters.' })
      }
      if (!respondent?.email) {
        errors.push({ name: `email-${respondent.id}`, error: 'Email is required.' })
      }
      if (respondent?.email?.toLowerCase().trim() === userData?.userEmail?.toLowerCase().trim()) {
        errors.push({ name: `email-${respondent.id}`, error: 'Please use email other than your login email.' })
      }
      if (!emailRegex.test(respondent?.email)) {
        errors.push({ name: `email-${respondent.id}`, error: 'Enter a valid email.' })
      }
      if (respondent?.email?.length > 50) {
        errors.push({ name: `email-${respondent.id}`, error: 'Max limit is 50 characters.' })
      }
    })
    setRespondentValidationErrors(errors)
    return errors.length === 0
  }

  const launchAssessment = () => {
    const hasError = validateRespondents()
    if (hasError) {
      const apiUrl = serviceURL.pgpBaseAPI + '/api/assessments/relaunch'
      setShowSpinner(true)
      let postData = {}
      if (respondentEmail && assessRowDetails) {
        postData = {
          description: description,
          daysToRespond: daysToRespond,
          respondents: respondentEmail,
          assessmentId: assessRowDetails?.id
        }
      }
      axiosInstance
        .put(apiUrl, postData, {
          headers: headers
        })
        .then(() => {
          setShowSpinner(false)
          setIsToShowBanner(false)
          setBannerMsg('')
          setShowRelaunchToast(true)
          setTriggerReloadDispatcher(true)
          setTimeout(() => {
            onClose && onClose()
          }, 1200)
        })
        .catch(error => {
          setShowSpinner(false)
          setIsToShowBanner(true)
          setBannerMsg(error.response.data.message)
          console.log(error)
        })
    }
  }

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setDescription(event?.target.value)
  }

  return (
    <>
      <Container maxWidth="xl">
      {showRelaunchToast && (
          <WppToast
            className={styles.toast}
            message="Assessment relaunched successfully"
            type="success"
            duration={2000}
            onWppToastComplete={() => setShowRelaunchToast(false)}
          />
        )}
        <WppModal open={relaunchModal} size="m" onWppModalClose={onClose} className={styles.modalBox}>
          <div slot="header" className={styles.relaunchHeader}>
            <h3 className={styles.assessNameProgressContainer}>
              <span>{assessRowDetails?.name}</span>
              {bannerMsg && (
                <div className={styles.loadingArea}>
                  <WppBanner type="information" closable={true} show={isToShowBanner} className={styles.banner}>
                    {bannerMsg}
                  </WppBanner>
                </div>
              )}
            </h3>
            <WppIconClose color="black" onClick={onClose} className={styles.close} size="m" />
          </div>

          <div slot="body" className={styles.relaunchModalBody}>
            <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }} rowSpacing={2}>
              <Grid item xs={12} sm={5} md={5}>
                <WppLabel
                  className={styles.label}
                  config={{ text: 'Assessment Name' }}
                  htmlFor="assessment-name"
                  typography="s-strong"
                />
                <WppInput value={assessRowDetails?.name} disabled={true} size="s" />
              </Grid>
              <Grid item xs={12} sm={7} md={7} style={{ marginBottom: '15px' }}>
                <WppLabel
                  className={styles.label}
                  config={{ text: 'Number of Response Days' }}
                  htmlFor="response-days"
                  typography="s-strong"
                />
                <WppSelect
                  id="response-days"
                  onWppChange={event => setDaysToRespond(event.target.value)}
                  placeholder="Number Of Days To Respond"
                  value={daysToRespond}
                  withSearch={true}
                  size="s"
                  message="These are calendar days - not working days. Weekends and holidays are included."
                  className={styles.respondDays}
                >
                  {daysOptions.map(days => (
                    <WppListItem key={days} value={days}>
                      <p slot="label">{days}</p>
                    </WppListItem>
                  ))}
                </WppSelect>
              </Grid>

              <EmailRespondent
                value={respondentEmail}
                setValue={setRespondentEmail}
                validationErrors={respondentValidationErrors}
              />

              <Grid item xs={12} sm={12} md={12}>
                <WppLabel
                  className={styles.label}
                  config={{ text: 'Note For Respondent' }}
                  htmlFor="description"
                  typography="s-strong"
                />
                 <TextArea
                  id="description"
                  placeholder="Enter Note For Respondant"
                  value={description}
                  onChange={e => handleChange(e)}
                />
              </Grid>
            </Grid>
          </div>
          <div slot="actions" className={styles.btnClose}>
            <Grid item xs={12} sm={12} md={12} display="flex" justifyContent="right">
              <WppButton
                className={styles.customBtnSecondary}
                variant="secondary"
                loading={showSpinner}
                onClick={launchAssessment}
              >
                Launch Assessment
              </WppButton>
            </Grid>
          </div>
        </WppModal>
      </Container>
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(RelaunchAssessment)
