import React from 'react'

const TagTotalRiskIndicator = (params: {
  data: { riskColor: string; riskBgColor: string }
  value: number | undefined | null
}) => {
  return (
    <>
      {params?.value !== null && params?.value !== undefined ? (
        <span
          style={{ color: params?.data?.riskColor, backgroundColor: params?.data?.riskBgColor, padding: '2px 5px', borderRadius: '5px' }}
        >
          {params?.value}
        </span>
      ) : null}
    </>
  )
}

export default TagTotalRiskIndicator